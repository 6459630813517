@import "../CommonStyles/CommonColors.scss";
@import "../CommonStyles/CommonStyles.scss";

.left-block {
  // position: fixed;
  // left: 0;
  // top: 0;
  // height: 100vh;
  // width: 20vw;
  // box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  // display: flex;
  // flex-direction: column;
  // z-index: 1;
  // background-color: #fff;
  // padding-top: 12vh;
  // width: 25%;
  .left-logo {
    font-size: 25pt;
    margin-bottom: 10px;
    padding: 15px;
    gap: 10pt;
    display: flex;
    align-items: center;
    justify-content: center;
    .left-logo-details {
      display: flex;
      flex-direction: column;
      font-size: 12pt;
    }
  }
  .left-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f5f5f5;
    padding: 10px;
   
    .left-links {
      // display: flex;
      // flex-direction: column;
      // width: 85%;
      // padding: 10px;
      .active {
        background-color: $highlight;
        border-radius: 0.8rem;
      }
      span {
        font-size: 14pt;
        font-weight: 700;
        border-bottom: 1.5px solid #e4e4e4;
        margin: 5pt;
        padding: 5px;
        .left-icon {
          padding: 0px 10px;
          font-size: 15pt;
        }
      }
      span:hover {
        cursor: pointer;
      }
    }
  }
}

.wallet-container {
  // width: 75%;
  .wallet-block {
    margin: 20pt 10pt 20pt 0pt;
    border-radius: 0.8rem;
    border: 1px solid $primary;
    box-shadow: $shadow1;
    .wallet-header {
      font-size: 18pt;
      font-weight: bolder;
      padding: 10pt;
      border-bottom: 1px solid #d4d3d3;
      display: flex;
      align-items: center;

      div {
        font-size: 10pt;
        border-radius: 0.8rem;
        padding: 10pt;
        border: 1px solid $primary;
        margin-left: 40pt;
        font-weight: lighter;
        span {
          font-size: 10pt;
          font-weight: bolder;
        }
      }
    }
    .wallet-content {
      margin: 10pt;
      .wallet-content-tabs {
        display: grid;
        width: 100%;
        justify-items: center;
        grid-template-columns: repeat(3, 1fr);
        span {
          text-align: center;
          cursor: pointer;
        }
        .active {
          border-bottom: 4px solid $highlight;
        }
      }
      .wallet-razorUPI {
        padding: 10pt;
        .razorUPI-input {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 5vw;
          margin: 20pt;
          span {
            font-weight: 400;
            font-size: 12pt;
          }
          input {
            outline-width: 1px;
            box-shadow: $shadow1;
            font-size: 1.25rem;
            text-align: center;
            padding: 0.5rem;
            background-color: white;
            border: 1px solid rgb(199, 202, 209);
            border-radius: 0.8rem;
          }
          button {
            box-shadow: $shadow1;
            font-weight: 500;
            padding: 0.5rem 1rem;
            border: none;
            border-radius: 0.8rem;
            background-color: $primary;
            color: white;
          }
        }
        .wallet-fees {
          span {
            color: #61c4f1;
          }
        }
      }
      .wallet-razorBank {
        padding: 10pt;
      }
      .bank-container {
        display: flex;
       
        
        .wallet-Bank {
          margin: 25pt 0;
          display: grid;
          width: 50%;
          border-radius: 0.8rem;
          border: 1px solid $primary;
          .bankTransfer {
            display: flex;
            flex-direction: column;
            gap: 10pt;
            span {
              font-size: 12pt;
              font-weight: 800;
              margin: 5pt 0;
              padding: 0 10pt;
              border-bottom: 1px solid $primary;
            }
            .bankTransfer-details {
              margin: 10pt 0;
              display: flex;
              flex-direction: column;
              font-size: 10pt;
              span {
                padding: 5pt;
                border-radius: 0.8rem;
                border: none;
                font-weight: normal;
                .details {
                  font-weight: 800;
                }
              }
            }
          }
          .UPITransfer {
            display: flex;
            flex-direction: column;
            gap: 10pt;
            span {
              font-size: 12pt;
              font-weight: 800;
              margin: 5pt 0;
              padding: 0 10pt;
              border-bottom: 1px solid $primary;
              border-top: 1px solid $primary;
            }
            .UPITransfer-details {
              margin: 10pt 0;
              display: flex;
              flex-direction: column;
              font-size: 10pt;
              span {
                padding: 5pt;
                border-radius: 0.8rem;
                border: none;
                font-weight: normal;
                .details {
                  font-weight: 800;
                }
              }
            }
          }
        }
        .wallet-bank-form {
          padding: 10pt;
          width: 50%;
          .wallet-form-header {
            font-size: 12pt;
            font-weight: 800;
            margin: 5pt 0;
          }
          .wallet-form {
            display: grid;
            gap: 10pt;
          margin-top: 10px;
            input {
              width: 100%;
              outline-width: 1px;
              box-shadow: $shadow1;
              font-size: 1rem;
              padding: 0.5rem;
              background-color: white;
              border: 1px solid rgb(199, 202, 209);
              border-radius: 0.8rem;
            }
            select {
              width: 100%;
              outline-width: 1px;
              box-shadow: $shadow1;
              font-size: 1rem;
              padding: 0.5rem;
              background-color: white;
              border: 1px solid rgb(199, 202, 209);
              border-radius: 0.8rem;
            }
          }
          .wallet-form-btn {
            display: flex;
            justify-content: center;
            margin-top: 10px;
            button {
              box-shadow: $shadow1;
              font-weight: 500;
              padding: 0.5rem 1rem;
              border: none;
              border-radius: 0.8rem;
              background-color: $primary;
              color: white;
            }
          }
        }
      }
    }
  }
  .transactions-block {
   
    margin: 20pt 10pt 20pt 0pt;
    border-radius: 0.8rem;
    border: 1px solid $primary;
    box-shadow: $shadow1;
    .transactions-header {
      font-size: 18pt;
      font-weight: bolder;
      padding: 10pt;
      border-bottom: 1px solid #d4d3d3;
      display: flex;
      align-items: center;

      div {
        font-size: 10pt;
        border-radius: 0.8rem;
        padding: 10pt;
        border: 1px solid $primary;
        margin-left: 40pt;
        font-weight: lighter;
        span {
          font-size: 10pt;
          font-weight: bolder;
        }
      }
    }
    .transactions-content {
      .transactions-content-header {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        justify-items: center;
        background-color: $highlight;
        padding: 10pt 0;
      }
      .transactions-content-body {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        justify-items: center;
        padding: 10pt 0;
        .icon {
          font-size: 10pt;
        }
        &.even {
          background-color: #e1e2e6;
        }
      }
    }
  }
}

@media screen and (width <= 700px) {
  .bank-container{
    flex-wrap: wrap;
    flex-direction: column;
    .wallet-bank-form{
      width: 100% !important;
    }
  }
  .wallet-container .wallet-block .wallet-content .bank-container .wallet-Bank {
    width: 100%;
  }
  .wallet-container .wallet-block .wallet-header {
    font-size: 14pt;
  }
  .wallet-container .transactions-block {
    margin: auto;
    width: 95%;
    margin-top: 10px;

  }
}